/**
 * Create the redux store
 */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { intelDataSlice } from '../slices/IntelDataSlice';
import { collectionRequestSlice } from '../slices/CollectionRequestSlice';
import { atrSlice } from '../slices/AtrSlice';
import { iSpySlice } from '../slices/IspySlice';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const rootReducer = combineReducers({
  collectionRequests: collectionRequestSlice.reducer,
  atr: atrSlice.reducer,
  intelData: intelDataSlice.reducer,
  iSpy: iSpySlice.reducer,
});

//Used currently to initialize store by when loading & for unit tests only.
export const initStore = () => {
  // const reducer = {
  //   collectionRequests: collectionRequestSlice.reducer,
  //   intelData: intelDataSlice.reducer,
  //   intelDataStatus: intelDataStatusSlice.reducer,
  // };

  //This will be enhanced as features/functionality is required
  const store = configureStore({
    reducer: rootReducer,
    // middleware: getDefaultMiddleware =>
    //   getDefaultMiddleware({
    //     serializableCheck: false,
    //   }),
    // middleware: () => {
    //   return getDefaultMiddleware();
    // },
    // middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
    // middleware: (getDefaultMiddleware) =>  getDefaultMiddleware(),
    // TODO --20240108 enable when state is stable
    // devTools: process.env.NODE_ENV !== 'production',
    devTools: true,
  });

  return store;
};

export const setupStoreForTest = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type AppStore = ReturnType<typeof setupStoreForTest>;

//Components requiring access to redux should import this store
export const store = initStore();

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

type ISpy = { id: string; data: any };

type ImageUrlData = { imageId: string; url: string };

type AtrDetectViewImageType = { url: string; feature: any; lat: any; lon: any };

const ispyAdapter = createEntityAdapter<ISpy>({
  selectId: cr => cr.id,
  sortComparer: (a, b) => a.data.localeCompare(b.data),
});

interface ExtendedEntityAdapterState {
  selectedDetects: any;
  showAll: boolean;
  iSpyOpen: boolean;
  clientId: null | string;
  lastEventTimestamp: null | Date;
}

const initialState: ExtendedEntityAdapterState = {
  selectedDetects: [],
  showAll: true,
  iSpyOpen: false,
  clientId: null,
  lastEventTimestamp: null,
};

export const iSpySlice = createSlice({
  name: 'iSpy',
  initialState: ispyAdapter.getInitialState(initialState),
  reducers: {
    launch(state, action) {
      iSpyOpenAction(action.payload);
      state.iSpyOpen = true;
    },
    viewImage(state, action) {
      const { imageId, url } = buildViewImageUrl(action.payload);
      console.log('TODO: view image url ', url);
      iSpyOpenAction(url);
      state.iSpyOpen = true;
    },
    viewDetects(state, action) {
      console.log('TODO: view detects');
      iSpyOpenAction(action.payload);
      state.iSpyOpen = true;
    },
    onEvent(state, action) {
      console.log('TODO: iSpy event event ', JSON.stringify(action));
      state.lastEventTimestamp = new Date();
    },
  },
});

const buildViewImageUrl = (payload: AtrDetectViewImageType) => {
  const imageId = payload.feature.properties['Image Location'];
  //lat=12.234&lon=98.765
  // const url = `${payload.url}/image?source_select=true&imageid=${image}`;
  let url = `${payload.url}?remote=true&source_select=true&imageid=${imageId}`;
  if (payload.lat && payload.lon) {
    url = `${url}&lat=${payload.lat}&lon=${payload.lon}`;
  } else {
    url = `${url}&fit=true`;
  }
  const data: ImageUrlData = { imageId, url };
  return data;
};

const iSpyOpenAction = url => {
  if (url) {
    window.open(url, '_blank', 'noopener, noreferrer');
  }
};

const { launch, viewImage, viewDetects } = iSpySlice.actions;
